import React, { useState } from 'react';
// import { addTransition, removeTransition } from '../../../../../Redux/Actions/VideoActions';
import { useDispatch, useSelector } from 'react-redux';
import { GiCheckMark } from 'react-icons/gi';
import { FaSpinner } from 'react-icons/fa';
import { addTransition, removeTransition } from '../../../../Redux/Actions/VideoActions';

const TransCard = (props) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [state, setState] = useState({
        active: false,
        loader: false
    })

    const handleTransition = () => {
        dispatch(addTransition(props.name, selectedSlideIndex))
    }
    const handleRemove = (e) => {
        e.stopPropagation()
        dispatch(removeTransition(selectedSlideIndex))
    }

    const handleActive = () => {
        setState({
            ...state,
            loader: true,
            active: true
        })

    }

    const handleLeave = () => {
        setState({
            ...state,
            loader: false,
            active: false
        })
    }
    return (
        <div className="col-6">
            <div
                className="photo-single"
                style={{ cursor: "pointer" }}
                onClick={() => handleTransition()}
                onMouseEnter={() => handleActive()}
                onMouseLeave={() => handleLeave()}
            >
                <img
                style={{objectFit:'cover'}}
                    alt=""
                    src={state.active ? props.preview : props.thumbnail}
                    onLoad={() => setState({
                        ...state,
                        loader: false,
                    })}
                />

                <div className="trans-name text-center">
                    {state.loader ?
                        <i className='fa fa-spinner fa-spin ' style={{ fontSize: 20, color: "#ee1085" }} />
                        : <span className='' style={{color:"#fff",fontSize:"13px",fontWeight:"500"}}>{props.name}</span>
                    }
                </div>
                {selectedSlide.transitionName === props.name ?
                    <div className='select-tile-style'  onClick={handleRemove}>
                        <GiCheckMark />
                    </div>
                    : ''
                }
            </div>
        </div>
        // <li>
        //     <div
        //         className='list-images-single'
        //         style={{ cursor: "pointer" }}
        //         onClick={() => handleTransition()}
        //         onMouseEnter={() => handleActive()}
        //         onMouseLeave={() => handleLeave()}
        //     >
        //         <img
        //             alt=""
        //             src={state.active ? props.preview : props.thumbnail}
        //         />
        //         {selectedSlide.transitionName === props.name ?
        //             <div className='select-tile-style' onClick={handleRemove}>
        //                 <GiCheckMark />
        //             </div>
        //             : ''
        //         }
        //         <div className='transition-txt text-capitalize fetch-loader-block' style={{ fontWeight: 600, fontSize: 12 }}>
        //             {state.loader ?
        //                 <i className='fa fa-spinner fa-spin fetch-loader' />
        //                 : <span className='text-light'>{props.name}</span>
        //             }
        //         </div>
        //     </div>


        // </li>
    );
}

export default TransCard;
