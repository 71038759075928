import React from 'react'
import SettingMenu from './SettingMenu'
import SettingContent from './SettingContent'
import Header from '../../../header/Header'
import Footer from '../../../footer/Footer'
import TitleBar from '../../../title-bar/TitleBar'

const Settings = () => {
  return (
    <>
      <Header />
      <TitleBar title="Settings" />
      <div className="site-wrap" style={{ paddingBottom: "0", minHeight: "100%" }}>
        <div className="site-container" style={{ maxWidth: "100%", paddingLeft: "0", paddingRight: "0" }}>
          <div className="site-container for-editor" style={{ maxWidth: "100%", padding: "0" }}>
            <div className="row">
              <div className="col-lg-4 tabLeft">
                <div className="upgrade-left">
                  <SettingMenu />
                </div>
              </div>

              <SettingContent />


            </div>
          </div>
        </div>
      </div >

      <Footer />
    </>
  )
}

export default Settings