import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateScript } from '../../../../Redux/Actions/VideoActions'
// import { updateScript } from '../../../../Redux/Actions/VideoActions'

const Script = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const updateStoryTile = (index) => {
        if (selectedSlideIndex !== index) {
            dispatch(updateScript(index, selectedSlideIndex))
        }
    }

    return (
        <>
            <h2 className='tab-title'>Scripts</h2>
            <div className="left-scroll">
                <div className="story-list">
                    {campaign.slides.length > 0 ?
                        campaign.slides.map((curElem, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`story-list-single ${+selectedSlideIndex === +index ? "active" : ""} text-lightn`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => updateStoryTile(index)}
                                >
                                    <p>{curElem.scene}</p>
                                </div>
                            )
                        }) : ""}

                </div>
            </div>
        </>
    )
}

export default Script