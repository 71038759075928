import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GiCheckMark } from "react-icons/gi"
import { FaSpinner } from "react-icons/fa"
import Variations from "./Variations";
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import { onSelectedTemplate } from "../../../../Redux/Actions/VideoActions";

const EditTemplate = ({ setPreLoader }) => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const [data, setData] = useState([])
    const [loader, setLoader] = useState({ fetch: false })

    const fetchTemp = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-backgrounds", {}, setData, loader, setLoader))
    }

    const handleSelectTemplate = (curElem) => {
        dispatch(onSelectedTemplate(curElem))
    }

    useEffect(() => {
        fetchTemp()
    }, [])

    return (
        <>
            <h2 className='tab-title'>Templates</h2>
            <div className="left-scroll">
                <div className="row sm">

                    {
                        data.length > 0 ?
                            data.map((curElem, index) => {
                                // let imageData = campaign.dimension === "9:16" ? curElem.preview_vertical : curElem.previewImage
                                return (
                                    <div className="col-6" key={index}>
                                        <div
                                            className="photo-single"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleSelectTemplate(curElem)}
                                            title={curElem.id}
                                        >
                                            <img alt="" src={curElem.url} />
                                            {curElem.url === selectedSlide.background[0].src ?
                                                <div className='select-tile-style' >
                                                    <GiCheckMark />
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            :
                            loader.fetch ? <div className='col-12 text-center mt-3' ><FaSpinner className="spin" style={{ fontSize: 25, color: "#00c4a6" }} /></div> : ""
                    }
                </div>

                <h2 className='tab-title mt-3'>Variations</h2>

                <Variations setPreLoader={setPreLoader} />

            </div>
        </>
    )
}

export default EditTemplate;