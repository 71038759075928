import React from 'react'

const DfyArticles = () => {
    return (
        <>
            <div className="tabepaneIn">
                <h2 className="tabTitle">DFY Articles</h2>
                <div className="account-block alt">
                    <div className="tabepaneIn-main">
                        <div className="tabepaneIn-cont">
                            {/* <h3>DFY Articles</h3> */}

                            <div className="row  d-flex justify-content-center">
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 1 </span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 6 </span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row  d-flex justify-content-center  mt-3">
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                target="_blank"
                                            >
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 2 </span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip"
                                                target="_blank"
                                            >
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 7 </span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row  d-flex justify-content-center  mt-3">
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                target="_blank"
                                            >
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 3 </span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                target="_blank"
                                            >
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 8 </span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row  d-flex justify-content-center  mt-3">
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 4</span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                target="_blank"
                                            >
                                                {" "}
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 8 </span> </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row  d-flex justify-content-center  mt-3">
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip"
                                                target="_blank"
                                            >
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 5 </span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="upgrade-box-content text-center  text-white">
                                        <div className="upgrade-para p-3">
                                            <a
                                                href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip"
                                                target="_blank"
                                            >
                                                <button class="upgrade-box-content text-center p-3 text-white site-link"> <span> Article Pack 10 </span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DfyArticles