import React, { useEffect, useState } from 'react'
// import modalCloseIcon from "../../images/modal-close.png";
import { Modal } from "react-bootstrap";
import { Player } from "@remotion/player";
import { useSelector } from 'react-redux';
import RemotionEditor from './RemotionEditor';
import { useRef } from 'react';

const Remotion = ({ show, handleClose }) => {

    const playerRef = useRef(null);
    const campaign = useSelector(state => state.video.campaign)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        let introDuration = (campaign?.intro?.enable === true && campaign?.intro?.src !== "") ? +campaign.intro.duration * 30 : 0
        let outroDuration = (campaign?.outro?.enable === true && campaign?.outro?.src !== "") ? +campaign.outro.duration * 30 : 0
        let dur = 0 + introDuration + outroDuration
        let audioDelay = 30
        if (campaign.slides?.length > 0) {
            campaign.slides.forEach((element, index) => {
                dur += +element.duration * 30
                if (index === 0) {
                    dur += (campaign?.intro?.enable === true && campaign?.intro?.src !== "") ? audioDelay : 0
                } else if (index === campaign.slides?.length - 1) {
                    // 1 sec extra for proper voice over in the end
                    dur += (campaign?.outro?.enable === true && campaign?.outro?.src !== "") ? 0 : audioDelay
                } else {
                    dur += audioDelay
                }
            });
            setDuration(dur)
        }
    }, [campaign])
    return (
        <Modal className={`theme-modal video_player_render ${campaign.dimension === "16x9" ? "remotion-preview_16X9 xl" : campaign.dimension === "9x16" ? "remotion-preview_9X16" : "remotion-preview_1X1"}`} show={show} onHide={handleClose} centered scrollable>
            <Modal.Body>
                <div style={{
                    // width: campaign.dimension === "16x9" ? 640 : campaign.dimension === "9x16" ? 360 : 460,
                    // height: campaign.dimension === "16x9" ? 360 : campaign.dimension === "9x16" ? 640 : 460,
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    display: "flex", justifyContent: "center"
                }}
                >
                    <Player
                        ref={playerRef}
                        component={RemotionEditor}
                        inputProps={{
                            slideData: campaign,
                            multiplyNum: 1,
                            playerRef: playerRef
                        }}
                        durationInFrames={duration}
                        compositionWidth={campaign.dimension === "16x9" ? 854 : campaign.dimension === "9x16" ? 360 : 460}
                        compositionHeight={campaign.dimension === "16x9" ? 480 : campaign.dimension === "9x16" ? 640 : 460}
                        fps={30}
                        controls={true}
                        style={{ height: "100%", border: "1px solid #201f1f", backgroundColor: "black" }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "1px solid #000" }}>
                <button className='site-link bdr' onClick={handleClose}><span>Cancel</span></button>
            </Modal.Footer>
        </Modal>
    )
}

export default Remotion
