import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { onFetchProjects } from '../../../Redux/Actions/ProjectActions'
import VideoCard from '../../CommonComponent/VideoCard'
import { fetchData } from '../../../Redux/Actions/CommonActions'
import MergeCard from '../../CommonComponent/MergeCard'

const VideoProjectContainer = (props) => {
    const dispatch = useDispatch()
    const [videos, setVideos] = useState([])
    const [mergeVideos, setMergeVideos] = useState([])
    const [loader, setLoader] = useState(false)
    const [mergeLoader, setMergeLoader] = useState({
        fetch: false
    })

    const fetchProjects = () => {
        setLoader(true)
        let limit = props.isDash ? 4 : false
        dispatch(onFetchProjects(setVideos, limit, setLoader))
    }

    const fetchMerge = () => {
        setMergeLoader({
            ...mergeLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-merge-campaigns", {}, setMergeVideos, mergeLoader, setMergeLoader))
    }


    useEffect(() => {
        if (!props.isDash && +props.type === 2) {
            fetchMerge()
        }
        if (+props.type === 1) {
            fetchProjects()
        }
    }, [props.type])

    return (
        <div className='video-project mt-0'>
            <div className={`${props.dimension === "9x16" ? "col-7 m-auto" : ""} video-project-wrap`}>
                {+props.type === 1 ?
                    <div className='row'>
                        {videos.length > 0 ?
                         videos.filter((ele) => {
                            return props.dimension === ele.dimension || props.dimension === ""
                        }).filter((proj) => {
                            if (props.q !== undefined) {
                                return proj.name.toLowerCase().includes(props.q.toLowerCase())
                            } else {
                                return proj
                            }
                        }).length>0?
                            videos.filter((ele) => {
                                return props.dimension === ele.dimension || props.dimension === ""
                            }).filter((proj) => {
                                if (props.q !== undefined) {
                                    return proj.name.toLowerCase().includes(props.q.toLowerCase())
                                } else {
                                    return proj
                                }
                            }).map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <VideoCard
                                            curElem={curElem}
                                            videos={videos}
                                            setVideos={setVideos}
                                        />
                                    </React.Fragment>
                                )
                            }):<> <div className="col-md-12">
                            <h4 className="text-center load-icon-help mt-3">
                                {
                                    loader ?
                                        <i className="fa fa-spinner fa-spin ms-1" style={{ fontSize: 25, color: "#ee1085" }} />
                                        :
                                        <p style={{ fontSize: 18 }}>
                                           project Not found
                                        </p>
                                }
                            </h4>
                        </div></>
                            :
                            <div className="col-md-12">
                                <h4 className="text-center load-icon-help mt-3">
                                    {
                                        loader ?
                                            <i className="fa fa-spinner fa-spin ms-1" style={{ fontSize: 25, color: "#ee1085" }} />
                                            :
                                            <p style={{ fontSize: 18 }}>
                                                You do not have any "Projects" in your account.
                                            </p>
                                    }
                                </h4>
                            </div>
                        }
                    </div>
                    :
                    <div className='row'>
                        {mergeVideos.length > 0 ?
                        mergeVideos.filter((ele) => {
                            return props.dimension.replace("x", ":") === ele.ratio || props.dimension === ""
                        }).filter((proj) => {
                            if (props.q !== undefined) {
                                return proj.name.toLowerCase().includes(props.q.toLowerCase())
                            } else {
                                return proj
                            }
                        }).length>0?
                            mergeVideos.filter((ele) => {
                                return props.dimension.replace("x", ":") === ele.ratio || props.dimension === ""
                            }).filter((proj) => {
                                if (props.q !== undefined) {
                                    return proj.name.toLowerCase().includes(props.q.toLowerCase())
                                } else {
                                    return proj
                                }
                            }).map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <MergeCard
                                            curElem={curElem}
                                            video={mergeVideos}
                                            setVideos={setMergeVideos}
                                        />
                                    </React.Fragment>
                                )
                            })
                            :<div className="col-md-12">
                            <h4 className="text-center load-icon-help mt-3">
                                {
                                    mergeLoader.fetch ?
                                        <i className="fa fa-spinner fa-spin ms-1" style={{ fontSize: 25, color: "#ee1085" }} />
                                        :
                                        <p style={{ fontSize: 18 }}>
                                           Project Not found.
                                        </p>
                                }
                            </h4>
                        </div>:
                            <div className="col-md-12">
                                <h4 className="text-center load-icon-help mt-3">
                                    {
                                        mergeLoader.fetch ?
                                            <i className="fa fa-spinner fa-spin ms-1" style={{ fontSize: 25, color: "#ee1085" }} />
                                            :
                                            <p style={{ fontSize: 18 }}>
                                                You do not have any "Reelmerge Projects" in your account.
                                            </p>
                                    }
                                </h4>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default VideoProjectContainer