import React, { useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TitleBar from '../../../title-bar/TitleBar'
import Header from '../../../header/Header'
import Footer from '../../../footer/Footer'
import { Search } from 'react-iconly';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { FaSpinner } from "react-icons/fa"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { onUpdateTemplate } from '../../../../Redux/Actions/ProjectActions';
import { GiCheckMark } from 'react-icons/gi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setAlert } from '../../../../Redux/Actions/AlertActions';

const ChooseTemplate = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { cid } = queryString.parse(location.search)
  const [temp, setTemp] = useState([])
  const [templateData, setTemplateData] = useState([])
  const [category, setCategory] = useState([])
  const [templateId, setTemplateId] = useState(false)
  const [loader, setLoader] = useState({
    fetch: false,
    submit: false
  })
  const [search, setSearch] = useState({
    q: "",
    catSearch: "",
  })

  const fetchTemplates = () => {
    setLoader({
      ...loader,
      fetch: true
    })
    dispatch(fetchData("fetch-backgrounds", {}, setTemplateData, loader, setLoader))
  }

  const handleChoose = (id) => {
    setTemplateId(id)
  }

  const handleSubmit = () => {
    if (templateId && templateId !== "-1") {
      let data = { templateId, cId: cid }
      setLoader({
        ...loader,
        submit: true
      })
      dispatch(onUpdateTemplate(data, navigate, loader, setLoader))
    } else {
      dispatch(setAlert("Please Choose Template First", "danger"))
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setSearch({
      ...search,
      [name]: value
    })
  }

  const fetchCategory = () => {
    dispatch(fetchData("fetch-background-category", {}, setCategory))
  }

  const loadMoreTemplates = () => {
    if (search.catSearch === "" && search.q === "") {
      let arr = [...templateData]
      setTemp((prevData) => [...prevData, ...arr.slice(prevData.length, prevData.length + 27)]);
    }
  }

  useEffect(() => {
    if (search.catSearch === "" && search.q === "") {
      let arr = [...templateData]
      setTemp(arr.slice(0, 27));
    } else {
      let arr = [...templateData]
      arr = arr.filter((cur) => {
        return cur.category === search.catSearch || search.catSearch === ""
      }).filter((cur) => {
        return cur.style.toLowerCase().includes(search.q.toLowerCase())
      })
      setTemp(arr);
    }
  }, [templateData, search])

  useEffect(() => {
    // fetchCategory()
    fetchTemplates()
  }, [])

  return (
    <>
      <TitleBar title="Projects" />
      <Header />

      <section className='page-wrap' style={{ paddingBottom: "10px" }}>
        <div className="breadCr">
          <div className="container">
            <ul>
              <li><Link to="/home">Dashboard</Link></li>
              <li>/</li>
              <li><Link to={`/projects/create-video?cid=${cid}`}>Create Video</Link></li>
              <li>/</li>
              <li><span style={{ color: "#f7ab3a", fontWeight: 600 }}>Templates</span></li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h1 className='page-title'>Choose Template</h1>
            </div>
            <div className="col-md-2">
              <button className='site-link full' type='button' onClick={handleSubmit}><span> Next {loader.submit ? <i className="fa fa-spinner fa-spin ms-1" /> : ""} </span></button>
            </div>
          </div>

          <div className="row  align-items-center mt-4" >
            <div className='col-xl-12'>
              <div className='input-wrap with-icon'>
                <span className='inp-icon'><Search /></span>
                <input
                  className='inp'
                  type="text"
                  placeholder='Search'
                  name='q'
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* <div className="col-md-3">
              <FloatingLabel controlId="floatingSelect" >
                <Form.Select
                  aria-label="Floating label select example"
                  name='catSearch'
                  style={{ paddingTop: "0", paddingBottom: "0", paddingLeft: "22px", borderRadius: "50px" }}
                  onChange={handleChange}
                >
                  <option value={""}>All</option>
                  {category.length > 0 ?
                    category.map((curElem, index) => {
                      return (
                        <option value={curElem.category} key={index}>{curElem.category}</option>
                      )
                    })

                    : ""}
                </Form.Select>
              </FloatingLabel>
            </div> */}

          </div>
          <InfiniteScroll
            dataLength={temp.length}
            hasMore={true}
            next={() => loadMoreTemplates()}
            scrollableTarget={`templateDiv`}
            style={{ overflowX: 'hidden' }}
          >
            <div id={`templateDiv`} className="Choose_template">
              <div className="row mt-4">
                {temp.length > 0 ?
                  temp
                    // filter((cur) => {
                    //   return cur.category === search.catSearch || search.catSearch === ""
                    // })
                    .filter((cur) => {
                      return cur.style.toLowerCase().includes(search.q.toLowerCase())
                    }).map((curElem, index) => {
                      return (
                        <div className="col-md-4" key={index}>
                          <div className='Choose_template-single'>
                          <div className='Choose_template-img'><img src={curElem.url} alt="" onClick={() => handleChoose(curElem.id)} style={{ cursor: "pointer", border: templateId === curElem.id ? "2px solid #ee1085" : "" }} /></div>
                          {templateId === curElem.id ?
                            <div className='select-tile-style' >
                              <GiCheckMark />
                            </div> : ""}
                          <div className='text-center text-dark'>
                            <p><span>{curElem.style}</span></p>
                          </div>
                          </div>
                        </div>
                      )
                    })
                  : loader.fetch ? "" : <div div className='text-center mt-3'> <span>"Data Not Found"</span></div>
                }
                <div className='text-center mt-3'>
                  {loader.fetch ? <FaSpinner className="spin" style={{ color: "#ee1085", fontSize: 25 }} /> : null}
                </div>
              </div>
            </div>
          </InfiniteScroll>
        </div >
      </section >

      <Footer />
    </>
  )
}

export default ChooseTemplate