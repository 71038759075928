import React, { useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { appName } from '../../../Global/Global';
import { useDispatch, useSelector } from 'react-redux';
import { gdprAction, onChangeEmail } from '../../../Redux/Actions/AuthActions';
import SweetAlert from '../../CommonComponent/SweetAlert';

const Privacy = () => {
   const dispatch = useDispatch()
   const auth = useSelector(state => state.auth)
   const rebrand = useSelector(state => state.rebrand.data)

   const [email, setEmail] = useState("")
   const [loader, setLoader] = useState(false)

   const [sweet, setSweet] = useState({
      enable: false,
      id: false,
      confirmButtonName: "",
      text: "",
      ep: "",
      loader: false
   })

   const modalData = [
      {
         message: `${rebrand ? rebrand.name : appName} stores your private information. If you wish to download these data, you can do that by clicking Download My Data button.`
      },
      {
         message: 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
      },
      {
         message: 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
      }

   ]
   const performAction = () => {
      setSweet({
         ...sweet,
         loader: true
      })
      dispatch(gdprAction({}, sweet.ep, sweet, setSweet))
   }

   const handleClick = (index) => {
      setSweet({
         ...sweet,
         enable: true,
         id: index,
         text: modalData[index].message,
         confirmButtonName: index === 0 ? "Download" : index === 1 ? "Stop Processing" : "Delete Data",
         ep: index === 0 ? "download-user-data" : index === 1 ? "disable-account" : "delete-account"
      })
   }

   const onCancelDelete = () => {
      setSweet({
         ...sweet,
         enable: false,
         id: false,
         ep: "",
         loader: false
      })
   }

   const handleSubmit = (e) => {
      e.preventDefault()
      let data = {
         email: email
      }
      setLoader(true)
      dispatch(onChangeEmail(data, setLoader))
   }

   return (

      <>
         <TitleBar title="Privacy" />
         <Header />

         <section className='page-wrap'>
            <div className='container'>
               <h1 className='page-title'>Change Email</h1>
               <div className='cont-box'>
                  <form className='row' onSubmit={handleSubmit}>
                     <div className='col-sm-6'>
                        <div className='common-input-wrap mt-0'>
                           <label htmlFor="">Current email address</label>
                           <input
                              className='common-input'
                              type="email"
                              placeholder='test@gmail.com'
                              value={auth.user.email}
                              readOnly
                           />
                        </div>
                     </div>
                     <div className='col-sm-6'>
                        <div className='common-input-wrap mt-0'>
                           <label htmlFor="">New email address</label>
                           <input
                              className='common-input'
                              type="email"
                              placeholder={auth.user.email}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                           />
                        </div>
                     </div>
                     <div className='col-sm-12'>
                        <div className='common-input-wrap text-center'>
                           <div className='alert alert-primary'>Updating your email will log you out for re-login</div>
                        </div>
                     </div>
                     <div className='col-sm-12'>
                        <div className='common-input-wrap'>
                           <button className='site-link full' type='submit' ><span>{loader ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                        </div>
                     </div>
                  </form>
               </div>

               <h1 className='page-title pt-5'>Privacy</h1>

               <div className='privacy-wrap'>
                  <div className='privacy-block'>
                     <h3>Manage your data</h3>
                     <p>{rebrand ? rebrand.name : appName} stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music tiles along with TTS Text to speech. If you wish to download these data, you can do that -by clicking" download your data. </p>
                     <div className='client-btn text-end'>
                        <button
                           className='site-link'
                           onClick={() => handleClick(0)}
                        >
                           <span>Download My Data</span>
                        </button>
                     </div>
                  </div>
               </div>

               <div className='privacy-wrap'>
                  <div className='privacy-block'>
                     <h3>Manage your data</h3>
                     <p>If you wish to stop your private data from getting processed on {rebrand ? rebrand.name : appName}  you can click the button to do so. Please note that doing this will disable your account and you will not be able to use {rebrand ? rebrand.name : appName} further. In order to reactivate your account, you would have to contact our support team at support@vineasx.com. Please <span>Proceed With Caution</span>.  </p>
                     <div className='client-btn text-end'>
                        <button
                           className='site-link'
                           onClick={() => handleClick(1)}
                        >
                           <span>Stop Processing My Data</span>
                        </button>
                     </div>
                  </div>
               </div>

               <div className='privacy-wrap'>
                  <div className='privacy-block'>
                     <h3>Manage your data</h3>
                     <p>If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use {rebrand ? rebrand.name : appName} further. You cannot undo this step from support also. So if you wish to re-use {rebrand ? rebrand.name : appName} again, then you will have to start all over again from scratch. Please <span>Proceed With Caution</span>. </p>
                     <div className='client-btn text-end'>
                        <button
                           className='site-link'
                           onClick={() => handleClick(2)}
                        >
                           <span>Delete My Data</span>
                        </button>
                     </div>
                  </div>
               </div>

            </div>
         </section>

         <SweetAlert
            show={sweet.enable}
            message={sweet.text}
            confirmButtonColor={""}
            cancelButtonColor={""}
            confirmButtonName={sweet.confirmButtonName}
            cancelButtonName={"Cancel"}
            handleClose={onCancelDelete}
            performDelete={performAction}
            alertLoader={sweet.loader}
         />
         <Footer />
      </>
   )

}

export default Privacy;