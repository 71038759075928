import React, { useEffect, useState } from 'react'
import { BiTrash, BiChevronDownSquare } from "react-icons/bi"
import Dropdown from 'react-bootstrap/Dropdown';
import { BsBoxArrowInDown, BsChevronDown } from "react-icons/bs"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SweetAlert from './SweetAlert';
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from 'react-redux';
import { CgScreen } from 'react-icons/cg'
import { FaSpinner } from 'react-icons/fa';
import { baseURL } from '../../Global/Global';
import PublishModal from '../pages/Projects/PublishModal';
import OcpCopyModal from '../pages/Projects/OcpCopyModal';

const MergeCard = ({ curElem, videos, setVideos }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [memberShip, setMemberShip] = useState([])


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [ocp, setOcp] = useState({
        loader: false,
        data: "",
        show: false
    })


    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = curElem.output_video
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-merge", data, videos, setVideos, setSweet))
    }


    const handleOCP = (id) => {
        window.open(`${baseURL}ocp/${id}`, '_blank');
    }


    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <div className={`col-lg-3`}>
            <div className={curElem.ratio === "16:9" ? "video-project-16x9" : 'video-project-9x16'}>
                <div className={curElem.ratio === "16:9" ? 'video-project-img_16x9' : 'video-project-img_9x16'}>
                    <img alt="" src={curElem.preview_image} style={{ height: "100%", width: "100%" }} />
                </div>
                <div className="Video_Project_Render">
                    <div className="progress" style={{ height: 22, position: "relative", textAlign: "center" }}>
                        <div style={{ height: "100%", width: "100%", position: "absolute", top: 0 }}>
                            <span className="progress-bar-text " style={{ color: "#000", fontWeight: "700", fontSize: "13px" }}>{curElem.status}</span>
                        </div>
                    </div>

                </div>
                <div className="video-project-bottom">
                    <div>
                        <h6 style={{ fontSize: '15px', textTransform: "capitalize" }} title={curElem.id} >{curElem.name}</h6>
                        <p>{curElem.created.split(" ")[0]}</p>
                    </div>
                    <div className="drop-style-1">
                        <Dropdown>
                            <Dropdown.Toggle variant="r" id="dropdown-basic">
                                <BsChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    {curElem.output_video ?
                                        <>
                                            <li><Link onClick={handleDownload}><BsBoxArrowInDown /> Download</Link></li>
                                            <li><Link onClick={handleShow}><BiChevronDownSquare /> Publish</Link></li>
                                        </>
                                        : null}
                                    {+auth.user.isClientAccount === 1 ? "" :
                                        <>
                                            <li onClick={() => onDelete(curElem.id)}><Link><BiTrash /> Delete</Link></li>
                                        </>
                                    }
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <PublishModal
                curElem={curElem}
                show={show}
                handleClose={handleClose}
            />

            <OcpCopyModal
                setOcp={setOcp}
                ocp={ocp}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this project?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </div>
    )
}

export default MergeCard