import React, { useState } from 'react';
import wordpress from "../../../assets/images/round-icon-wordpress.png";
import download from "../../../assets/images/round-icon-download.png";
import importIcon from "../../../assets/images/round-icon-import.png";
import customize from "../../../assets/images/round-icon-customize.png";
import youtubeimg from "../../../assets/images/youtubeimg.jpg";
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai'
import modalCloseIcon from "../../../assets/images/modal-close.png";


function AgencyWebsite() {
  const [state, setState] = useState(false);
  const handleClose = () => setState(false)
  const [frameUrl, setFrameUrl] = useState();

  const addFrameUrl = () => {
    setState(true);
    setFrameUrl("https://player.vimeo.com/video/514577280?");
  };

  const closePop = () => {
    setState(false);
    setFrameUrl("");
  };
  return (
    <>
      <div className="tabepaneIn">
        <h2 className="tabTitle">Agency</h2>
        <div className="tabepaneIn-main">
          <div className="tabepaneIn-cont">
            <h3>Agency</h3>
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="upgrade-box-content p-2 text-white  ">
                  <div className="row">
                    <div className="col-md-2 pr-0 text-center ">
                      <img src={wordpress} alt="wprdpress" />
                    </div>
                    <div className="col-md-10">
                      <div className="upgrade-para">
                        <p className="m-0" style={{ fontSize: "16px" }}>
                          Fresh Install and configure wordpress at your
                          server. If you need help in installing and
                          configuring wordpress, Click here to find the
                          tutorial.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                  <div className="row">
                    <div className="col-md-2 pr-0 text-center ">
                      <img src={download} alt="download" />
                    </div>
                    <div className="col-md-10">
                      <div className="upgrade-para">
                        <p className="m-0" style={{ fontSize: "16px" }}>
                          Once wordpress is installed at your server.
                          Download the site content by clicking the
                          "Download site button". This will download a
                          wpress file which you will be needing to
                          import in your wordpress site.
                        </p>
                        <div className="row  d-flex justify-content-center">
                          <div className="col-md-12">
                            <div className="upgrade-box-content text-center  text-white">
                              <div className="upgrade-para p-3">
                                <a
                                  rel="noreferrer"
                                  href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                                  target="_blank"
                                >
                                  <button className="site-link btn-block mt-0">
                                    <span>Download the site</span>
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                  <div className="row">
                    <div className="col-md-2 pr-0 text-center ">
                      <img src={importIcon} alt="import" />
                    </div>
                    <div className="col-md-10">
                      <div className="upgrade-para">
                        <p className="m-0" style={{ fontSize: "16px" }}>
                          Import the xml file in to your wordpress site.
                          If you need help importing the xml file in
                          wordpress, Click here to find the tutorial.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                  <div className="row">
                    <div className="col-md-2 pr-0 text-center ">
                      <img src={customize} alt="customize" />
                    </div>
                    <div className="col-md-10">
                      <div className="upgrade-para">
                        <p className="m-0" style={{ fontSize: "16px" }}>
                          Customize the site as according to your need.
                          If you need help customizing the site. Click
                          here to find the tutorial
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tabepaneIn-cont mt-4">
            <h3>Tutorial</h3>
            <div className="row">
              <div className="col-md-12">
                <div className="upload-box-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="tutorial-content">
                        <div className="row">
                          <div className="col-md-2 text-center">
                            <img src={wordpress} alt="wordpress" />
                          </div>
                          <div className="col-md-10">
                            <div className="upgrade ml-lg-4">
                              <a
                                rel="noreferrer"
                                className="btn-block"
                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                target="_blank"
                              >
                                <button
                                  title="Install WordPress From cPanel"
                                  className="site-link btn-block mt-0"
                                >
                                  <span>Install WordPress From cPanel</span>
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-2 text-center">
                            <img src={importIcon} alt="" />
                          </div>
                          <div className="col-md-10">
                            <div className="upgrade ml-lg-4">
                              <a
                                rel="noreferrer"
                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                target="_blank"
                              >
                                <button className="site-link btn-block mt-0">
                                  <span>Import Website </span>
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-2 text-center">
                            <img src={customize} alt="" />
                          </div>
                          <div className="col-md-10">
                            <div className="upgrade ml-lg-4">
                              <a
                                rel="noreferrer"
                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                target="_blank"
                              >
                                <button className="site-link btn-block mt-0">
                                  <span>Customize website</span>
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-2 text-center">
                            <img src={download} alt="" />
                          </div>
                          <div className="col-md-10">
                            <div className="upgrade ml-lg-4">
                              <a
                                rel="noreferrer"
                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                target="_blank"
                              >
                                <button
                                  className="site-link btn-block mt-0"
                                  title="Upload Video and Usage in Website"
                                >
                                  <span>Upload Video and Usage in Website</span>
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tabepaneIn-cont mt-4">
            <h3>How to Install the Agency Website</h3>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card fb-card border-0">
                  <div
                    className="card-header text-center"
                    style={{ background: "rgb(0, 123, 255)" }}
                  >
                    <h6 className="text-white Account-Management-headings">
                      How to Install the Agency Website
                    </h6>
                  </div>
                  <div className="card-body p-0">
                    <img
                      src={youtubeimg}
                      alt=""
                      className="img-fluid"
                    />
                    <span
                      className="youtubeicon"
                      onClick={(e) => addFrameUrl()}
                      style={{ cursor: "pointer", color: "#007bff" }}
                      data-bs-toggle="modal"
                      data-bs-target="#MyModal"
                    >
                      <i className="fas fa-play-circle"></i>
                    </span>
                  </div>
                  <div></div>
                </div>


                <Modal className={`theme-modal video-modal xl`} show={state} onHide={handleClose} centered>
                  <Modal.Body>
                    <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                    <div className="ratio ratio-16x9">
                      <iframe class="embed-responsive-item" src={frameUrl} width="1920" height="1074" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="AiSocialsWalkthrough"></iframe>
                    </div>
                  </Modal.Body>
                </Modal>

              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )

}

export default AgencyWebsite;