import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { addRebrandData, onDeleteRebrand } from '../../../Redux/Actions/RebrandingAction';
import SweetAlert from '../../CommonComponent/SweetAlert';

import { PiUpload } from 'react-icons/pi'

const Rebranding = () => {
  const dispatch = useDispatch();
  const rebrandData = useSelector(state => state.rebrand);

  const [rebrand, setRebrand] = useState({
    name: "",
    img: "",
    id: false,
    isRebrand: false
  });
  const [uploadImg, setUploadImg] = useState(false)
  const [loader, setLoader] = useState(false);

  const [sweet, setSweet] = useState({
    enable: false,
    id: false,
    confirmButtonName: "Remove",
    loader: false
  })

  const onConfirm = () => {
    let data = { id: sweet.id }
    setSweet({
      ...sweet,
      confirmButtonName: "Removing",
      loader: true
    })
    dispatch(onDeleteRebrand(data, setSweet, setRebrand))
  }

  const onInputFile = (e) => {
    if (e.target.files.length > 0) {
      let fileData = e.target.files[0]
      if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
        if (fileData.size < 5000000) {
          setRebrand({
            ...rebrand,
            img: fileData
          })
          setUploadImg(URL.createObjectURL(fileData))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Max uploaded size for image is 5MB!',
            confirmButtonColor: "#00D0B0"
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You have selected invalid file type!',
          confirmButtonColor: "#00D0B0"
        })
      }
    }
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let formData = new FormData();
    formData.append('name', rebrand.name);
    formData.append('logo', rebrand.img);
    dispatch(addRebrandData(formData, setLoader));
  }

  const deleteRebrand = () => {
    setSweet({
      ...sweet,
      enable: true,
      id: rebrand.id
    })
  }


  const onCancel = () => {
    setSweet({
      ...sweet,
      enable: false,
      id: false,
      confirmButtonName: "Remove",
      loader: false
    })
  }

  useEffect(() => {
    if (rebrandData.data) {
      const { name, id, logo } = rebrandData.data
      setRebrand({
        ...rebrand,
        name: name,
        id: id,
        img: logo,
        isRebrand: true
      });
    }
  }, [rebrandData])


  return (
    <>
      <div className="upgrade-contentv">
        <h2 className='tabTitle pb-4'>Rebranding</h2>
        <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>

          <div className='upload-box large d-flex'>
            <PiUpload />
            <p>
              <p> Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x40) Pixels recommended</p>
            </p>
            <input
              type="file"
              id="customFile"
              name="file"
              onChange={(e) => onInputFile(e)}
            />
            {rebrand.img ?
              <img src={uploadImg ? uploadImg : rebrand.img} alt='' style={{ height: 40, marginTop: 10, width: 210 }} /> : null}
          </div>

          <div className='common-input-wrap'>
            <label htmlFor="">Brand Name</label>
            <input
              className="common-input"
              type="text"
              name="firstName"
              placeholder="Enter your brand Name"
              required
              value={rebrand.name}
              onChange={(e) => setRebrand({
                ...rebrand,
                name: e.target.value
              })}
              readOnly={rebrand.isRebrand}
            />
          </div>

          <div className='common-input-wrap text-center'>
            {rebrand.isRebrand === false ?
              <button
                type="submit"
                className="site-link me-1 mb-1"
                disabled={loader}><span>{loader ? <> Saving <i className="fa fa-spinner fa-spin mr-2" />  </> : 'Save'}</span></button>
              :
              <button
                type="button"
                onClick={deleteRebrand}
                className="site-link"
              >
                <span>
                  Remove Rebranding
                </span>
              </button>
            }
          </div>

        </form>
      </div>
      <SweetAlert
        show={sweet.enable}
        message={"Are you sure? Do you want to remove rebrnding?"}
        confirmButtonColor={""}
        cancelButtonColor={""}
        confirmButtonName={sweet.confirmButtonName}
        cancelButtonName={"Cancel"}
        handleClose={onCancel}
        performDelete={onConfirm}
        alertLoader={sweet.loader}
      />

    </>
  )

}

export default Rebranding;