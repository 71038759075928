import React, { useEffect, useState } from "react";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { IoPlay } from "react-icons/io5"
import UploadMusic from "../BgAudio/UploadMusic";
import { useDispatch, useSelector } from "react-redux";
import TextToSpeech from "./TextToSpeech";
import Recording from "./Recording";
import Sonority from "./Sonority";
import { setTtsData } from "../../../../Redux/Actions/VideoActions";
import { setAlert } from "../../../../Redux/Actions/AlertActions";

const VoiceOver = ({ setDeactiveButton }) => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [selectedAudio, setSelectedAudio] = useState(false)

    const [active, setActive] = useState({
        sonority: false,
        recording: false
    })

    const addVoiceover = (url, duration) => {
        const audio = {
            src: url,
            duration: +duration <= 0 ? 1 : duration,
            meta: {
                languageId: "",
                text: "",
                translateText: "",
                voiceId: "",
            }
        }
        if (+duration <= 30) {
            setSelectedAudio(url);
            dispatch(setTtsData(audio, selectedSlideIndex, true))
        } else {
            dispatch(setAlert("Max allowed duration is 30 seconds.", "danger"))
        }
    }

    const handleActive = (type) => {
        if (type === "sonority" && active.sonority === false) {
            setActive({
                ...active,
                sonority: true,

            })
        }
        else if (type === "recording" && active.recording === false) {
            setActive({
                ...active,
                recording: true
            })
        }
    }

    useEffect(() => {
        setSelectedAudio(campaign.slides[selectedSlideIndex].audio.src)
    }, [selectedSlideIndex])

    return (
        <>
            <h2 className='tab-title'>Voiceover</h2>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <Nav variant="b" className="innerTab mt-0">
                    <Nav.Item>
                        <Nav.Link eventKey="first">Robo Voice</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => handleActive("sonority")}>
                        <Nav.Link eventKey="second">Sonority</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="third">Upload</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => handleActive("recording")}>
                        <Nav.Link eventKey="fourth">Recording</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <TextToSpeech
                            setDeactiveButton={setDeactiveButton}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                        <Sonority
                            onSelectAudio={addVoiceover}
                            active={active}
                            selectedAudio={selectedAudio}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                        <UploadMusic
                            type="voiceover"
                            onSelectAudio={addVoiceover}
                            selectedAudio={selectedAudio}
                            setSelectedAudio={setSelectedAudio}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                        <Recording
                            active={active}
                            onSelectAudio={addVoiceover}
                            selectedAudio={selectedAudio}
                            setSelectedAudio={setSelectedAudio}
                        />
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>
        </>
    )
}

export default VoiceOver;