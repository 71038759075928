import React, { useEffect } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TitleBar from '../../../title-bar/TitleBar'
import Header from '../../../header/Header'
import Footer from '../../../footer/Footer'
import { Search } from 'react-iconly';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useState } from 'react';
import { onUpdateCharacter } from '../../../../Redux/Actions/ProjectActions';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { GiCheckMark } from 'react-icons/gi';
import { FaSpinner } from 'react-icons/fa';
import { setAlert } from '../../../../Redux/Actions/AlertActions';

const ChooseCharacter = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { cid } = queryString.parse(location.search)
  const [char, setChar] = useState([])
  const [characterId, setCharacterId] = useState(false)
  const [loader, setLoader] = useState({
    fetch: false,
    submit: false
  })
  const [activeAnim, setActiveAnim] = useState({
    index: -1
  })

  const [search, setSearch] = useState({
    q: "",
  })


  const fetchChar = () => {
    setLoader({
      ...loader,
      fetch: true
    })
    dispatch(fetchData("fetch-characters", {}, setChar, loader, setLoader))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setSearch({
      ...search,
      [name]: value
    })
  }

  const handleChoose = (id) => {
    setCharacterId(id)
  }

  const handleActiveAnim = (index) => {
    setActiveAnim({
      ...activeAnim,
      index: index
    })

  }

  const handleDeactiveAnim = () => {
    setActiveAnim({
      ...activeAnim,
      index: -1
    })
  }

  const handleSubmit = () => {
    if (characterId && characterId !== "-1") {
      let data = { characterId, cId: cid }
      setLoader({
        ...loader,
        submit: true
      })
      dispatch(onUpdateCharacter(data, navigate, loader, setLoader))
    } else {
      dispatch(setAlert("Please Select Character First", "danger"))
    }
  }

  useEffect(() => {
    fetchChar()
  }, [])

  return (
    <>
      <TitleBar title="Projects" />
      <Header />

      <section className='page-wrap' style={{ paddingBottom: "10px" }}>
        <div className="breadCr">
          <div className="container">
            <ul>
              <li><Link to="/home">Dashboard</Link></li>
              <li>/</li>
              <li><Link to={`/projects/create-video?cid=${cid}`}>Create Video</Link></li>
              <li>/</li>
              <li><Link to={`/projects/choose-template?cid=${cid}`}>Templates</Link></li>
              <li>/</li>
              <li><span style={{ color: "#f7ab3a", fontWeight: 600 }}>Characters</span></li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h1 className='page-title'>Choose Character</h1>
            </div>
            <div className="col-md-2">
              <button className='site-link full' type='button' onClick={handleSubmit}><span> Next {loader.submit ? <i className="fa fa-spinner fa-spin ms-1" /> : ""} </span></button>
            </div>
          </div>
          <div className="row  align-items-center mt-4" >
            <div className='col-xl-12'>
              <div className='input-wrap with-icon'>
                <span className='inp-icon'><Search /></span>
                <input
                  className='inp'
                  type="text"
                  placeholder='Search'
                  name='q'
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="Choose_template">

            <div className="row" >
              {
                char.length > 0 ?
                  char.filter((cur) => {
                    return cur.keywords.toLowerCase().includes(search.q.toLowerCase())
                  }).map((curElem, index) => {
                    return (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12" key={index} >
                        <div className="character-img-single"
                          
                          onMouseEnter={() => handleActiveAnim(index)} sdfsd
                          onMouseLeave={handleDeactiveAnim}
                          onClick={() => handleChoose(curElem.id)}
                        >
                          {index === activeAnim.index ?
                            <span style={{ position: "relative", height: "100%", width: "100%", display: "block", borderRadius: "15px", border: characterId === curElem.id ? "2px solid #ee1085" : "" }}>
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                              <object
                                data={curElem.url}
                                type='text/html'
                                style={{ pointerEvents: "none", width: "100%", height: "100%", }}
                              />
                            </span>

                            :
                            <img
                              src={curElem.url}
                              alt=""
                              style={{ cursor: "pointer", width: "100%", objectFit: "unset", border: characterId === curElem.id ? "2px solid #ee1085" : "" }}
                            />
                          }


                          {characterId === curElem.id ?
                            <div className='select-tile-style' >
                              <GiCheckMark />
                            </div> : ""}
                        </div>
                      </div>
                    )
                  })
                  : ""
              }
              {
                char.filter((cur) => {
                  return cur.keywords.toLowerCase().includes(search.q.toLowerCase())
                }).length > 0 ?
                  "" :
                  <div className='text-center mt-3'>
                    {loader.fetch ? <FaSpinner className="spin" style={{ color: "#ee1085", fontSize: 25 }} /> : <div className='text-center'> <span>"Data Not Found"</span></div>}
                  </div>
              }
            </div>
          </div>
        </div>
      </section >

      <Footer />
    </>
  )
}

export default ChooseCharacter