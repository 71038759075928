import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateCampaign = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/projects/create-video?cid=${res.data.id}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onUpdateCampaign = (data, navigate, setLoader, fetchCampaign) => (dispatch, getState) => {
    commonAxios("update-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (navigate) {
                    navigate(`/projects/choose-template?cid=${data.id}`)
                }
                if (fetchCampaign) {
                    fetchCampaign()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}
export const onFetchCampaign = (data, setData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = { ...res.data }
                if (data.results === "") {
                    data.results = []
                } else {
                    data.results = JSON.parse(data.results)
                }
                setData(data)
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onSearchContent = (data, setData, setLoader, isSearch) => (dispatch, getState) => {
    commonAxios("generate-ideas", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData({
                    ...data,
                    results: isSearch ? res.data : data.results.concat(res.data)
                })
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onUpdateScript = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-script", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.type === "save") {
                    dispatch(setAlert(res.msg, "success"))
                } else {
                    navigate(`/projects`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                next: false,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                next: false,
                save: false
            })
        })
}

export const onFetchProjects = (setProejcts, limit, setLoader) => (dispatch, getState) => {
    commonAxios("get-campaign", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = res.data
                if (limit) {
                    data = data.slice(0, limit)
                }
                setProejcts(data)
            } else {
                setProejcts([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onMergingVideo = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-merge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/projects?d=${data.ratio === "16:9" ? "hz" : "vc"}&p=${2}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onPublishVideo = (type, data, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios(type, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose(false);
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            handleClose(false);
            setLoader(false);
        })
}

export const onFetchScript = (data, setState, setCreateData, setActiveInterval, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data.originalScript) {
                    setState(res.data.savedScript ? res.data.savedScript : res.data.originalScript)
                } else {
                    setActiveInterval(true)
                }
                setCreateData(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}
export const onGetScript = (data, setState, setCreateData, setActiveInterval, interval) => (dispatch, getState) => {
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data.originalScript) {
                    setState(res.data.savedScript ? res.data.savedScript : res.data.originalScript)
                    setCreateData(res.data)
                    setActiveInterval(false)
                    clearInterval(interval)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            clearInterval(interval)
        })
}

export const onPlatform = (setState) => (dispatch, getState) => {
    commonAxios("fetch-platform", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onUpdateTemplate = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-template", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (navigate) {
                    navigate(`/projects/choose-character?cid=${data.cId}`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onUpdateCharacter = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-character", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (navigate) {
                    navigate(`/projects/create-script?cid=${data.cId}`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}