import React from 'react'

const SocialIcons = (props) => {
    return (
        <div className={`${props.status ? '' : 'disable-social-img'} cursor-pointer icon_hover ${props.selectIcon}`}  >
            <div className="img-wrraper">
                <img src={props.icon} alt={props.name} width="56px"  />
            </div>
            <p className="mt-1 m-0 text-center" style={{ fontSize: "12px" }}>{props.name}</p>
        </div>
    )
}

export default SocialIcons