import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { onDeleteOutreach } from "../../../../Redux/Actions/outreachAction";
import SweetAlert from "../../../CommonComponent/SweetAlert";
import { onDeleteOutreach } from "../../../../Redux/Actions/SettingsActions";

const SmtpClient = ({ row, index, handleShow, fetchOutreach }) => {
    const dispatch = useDispatch()

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleDelete = (idToDete) => {
        setSweet({
            ...sweet,
            id: idToDete,
            enable: true
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true,
        })
        dispatch(onDeleteOutreach(sweet, setSweet, fetchOutreach))
    }


    return (
        <>
            <tr>
                <td scope="row"><h6
                    className="text-wrapper  m-0">{index + 1}</h6></td>
                <td><h6 className="text-wrapper m-0">{row.name}</h6></td>
                <td><h6 className="text-wrapper m-0">{row.username}</h6></td>
                <td className="expand-button">
                    <span
                        className="action-icon delete-icon remove mb-1 mt-md-1 mx-1"
                        title="Edit"
                        aria-expanded="true"
                        onClick={() => handleShow(row.id)}
                        style={{cursor:"pointer"}}

                    >
                        <i className="fas fa-edit"></i>
                    </span>

                    <span
                        className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
                        style={{ cursor: "pointer", color: "rgb(240, 83, 89)" }}
                        title="Delete"
                        onClick={() => handleDelete(row.id)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </span>
                </td>
            </tr>
            <SweetAlert
                show={sweet.enable}
                message={"You want to delete this SMTP Information?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />



        </>
    )

}
export default SmtpClient;