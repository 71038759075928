import React, { useState } from 'react';

function ResellerLicense() {

  return (
    <>
      <div className="tabepaneIn">
        <h2 className="tabTitle">Reseller License</h2>
        <div className="tabepaneIn-main">
          <div className="tabepaneIn-cont">
            <h3>Reseller License</h3>
            <div className="upgrade-para">
              <p className="text-center " style={{ fontSize: "16px" }}>
                Go to JVZoo Dashboard and request your affiliate link.
                Once you've requested, submit a ticket with your ToonReel Social
                Business transaction ID, affiliate ID and
                email address for our team to activate your reseller
                license. Please allow upto 2 working days.
              </p>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="upgrade-box-content text-center  text-white">
                  <div className="">
                    <a
                      rel="noreferrer"
                      href="https://aisocials.io/jv/"
                      target="_blank"
                    >
                      <button className="site-link btn-block mt-0">
                        <span>Request Affiliate Link</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="upgrade-box-content text-center  text-white">
                  <div className="">
                    <a
                      rel="noreferrer"
                      href="https://support.vineasx.com/"
                      target="_blank"
                    >
                      <button className="site-link btn-block mt-0">
                        <span>Raise Support Ticket</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default ResellerLicense;