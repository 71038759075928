import React, { useState } from 'react';

function VideoFlix() {

  return (
    <>
      <div className="tabepaneIn">
        <h2 className="tabTitle">Video Flix</h2>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="tabepaneIn-main  text-white  ">
              <div className="tabepaneIn-cont">
                <h3>VideoFLIX - Netflix Style Video Site Builder</h3>
                <div className="upgrade-para">
                  <p className="text-center " style={{ fontSize: "16px" }}>
                    VideoFLIX is a subscription-based video content
                    management app built on cloud. Using VideoFLIX you
                    will be able to create your own video content sharing
                    website similar to NetFlix, HBO-Go, DisneyPLUS etc;
                    sharing your own or others unique video content as
                    channels or series. You will be able to create free,
                    one-time or monthly payment plans for your customers
                    to access your video content and build your video
                    content subscription business!
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row  mt-5">
          <div className="col-md-6">
            <div className="upgrade-box-content text-center p-3 text-white">
              <a
                rel="noreferrer"
                href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip"
                target="_blank"
              >
                <button className="site-link">
                  <span>Download VideoFlix</span>
                </button>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="upgrade-box-content text-center p-3 text-white">
              <a
                rel="noreferrer"
                href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip"
                target="_blank"
              >
                <button className="site-link">
                  <span>Download Tutorial Docs</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default VideoFlix;