import React from 'react'
import { FaSpinner } from "react-icons/fa"
const ScriptRightPanel = ({ scriptText, setScriptText, activeInterval }) => {
    return (
        <div className='col-xl-6 col-12'>
            <div className='cont-box sm-gap'>
                <div className='inp-bg'>
                    {activeInterval ?
                        <div className='d-flex justify-content-center align-items-center text-center text-dark' style={{ height: "100%", width: "100%" }}>
                            <div>
                                <p> Generating Script. Please be patient.</p>
                                <FaSpinner className="spin mt-2" style={{ color: "#ee1085", fontSize: 25 }} />
                            </div>
                        </div>
                        :
                        <>
                            <div className='inp-bg-top'>
                                <p><strong>You can edit your script here</strong></p>
                            </div>
                            <div className='inp-bg-mid'>
                                <textarea
                                    className='common-input'
                                    value={scriptText}
                                    onChange={(e) => setScriptText(e.target.value)}
                                />
                            </div>
                        </>
                    }
                    {/* <div className='inp-bg-bottom'>
                        <p>Slides : 0</p>
                      </div> */}
                </div>
            </div>
        </div>
    )
}

export default ScriptRightPanel