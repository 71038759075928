import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiCheckMark } from 'react-icons/gi';

import { FaSpinner } from 'react-icons/fa';
import { onVariationChange } from '../../../../Redux/Actions/VideoActions';
import { fetchData } from '../../../../Redux/Actions/CommonActions';

const Variations = ({ setPreLoader }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [loader, setLoader] = useState({ fetch: false })
    const [variation, setVariation] = useState([])


    const handleSelectTemplate = (id) => {
        if (selectedSlide.variationId !== id) {
            let data = {
                slideId: selectedSlide.id,
                newVariationId: id
            }
            dispatch(onVariationChange(data, selectedSlideIndex, setPreLoader))
        }
    }

    const getVariation = () => {
        setLoader({
            ...loader,
            fetch: true
        })
       
        dispatch(fetchData("fetch-variation", {}, setVariation, loader, setLoader))
    }

    useEffect(() => {
        if (campaign.templateId) {
            getVariation(campaign.templateId)
        }
    }, [campaign.templateId])

    return (

        <div className="row sm">
            {
                variation.length > 0 ?
                    variation.filter((obj) => {
                        let dim = obj.dimension === "1920x1080" ? "16x9" : "9x16"
                        return campaign.dimension === dim
                    }).map((curElem, index) => {
                        return (
                            <div className="col-6" key={index}>
                                <div className="photo-single" onClick={() => handleSelectTemplate(curElem.id)}>
                                    <img alt="" src={curElem.preview} />
                                    {+selectedSlide.variationId === +curElem.id ?
                                        <div className='select-tile-style'>
                                            <GiCheckMark />
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        )
                    }) :

                    loader.fetch ? <div className='col-12 text-center mt-3' ><FaSpinner className="spin" style={{ fontSize: 25, color: "#00c4a6" }} /></div> : ""}
        </div>
    );
}

export default Variations;
