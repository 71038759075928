import React, { useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import EditMemberModal from './EditMemberModal';
import SweetAlert from '../../CommonComponent/SweetAlert';
import { onDeleteAcc } from '../../../Redux/Actions/AccountManageAction';
import { useDispatch } from 'react-redux';

const MemberRows = ({ curElem, fetchMembers, type }) => {
    const dispatch = useDispatch()
    const [user, setUser] = useState({
        id: false,
        name: "",
        email: "",
        type: "",
        created: ""
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDelete = (tId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: tId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            client_id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteAcc(data, fetchMembers, setSweet))
    }

    useEffect(() => {
        setUser({
            ...user,
            id: curElem.id,
            name: curElem.name,
            email: curElem.email,
            type: curElem.type,
            created: curElem.created
        })
    }, [curElem])

    return (
        <>
            <tr>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.created}</td>
                <td className='text-center'>
                    <span className='smBtn edit' title='Edit' onClick={handleShow}><FiEdit /></span>
                    <span
                        className='smBtn'
                        title='Delete'
                        onClick={() => onDelete(user.id)}
                    >
                        <AiOutlineDelete />
                    </span>
                </td>
            </tr>


            <EditMemberModal
                show={show}
                handleClose={handleClose}
                user={user}
                type={type}
                fetchMembers={fetchMembers}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this member?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default MemberRows