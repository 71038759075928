import React, { useState, useEffect } from 'react';
import { BsPlayFill, BsFillLayersFill, BsFillChatDotsFill } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { Link } from 'react-router-dom';

import { RiUser3Fill, RiVideoAddFill } from "react-icons/ri"
import { FiChevronRight } from "react-icons/fi"
import { BsFillCollectionPlayFill } from "react-icons/bs"
import { MdCastConnected } from "react-icons/md"
import CreateModal from '../Projects/CreateProject/CreateModal';
import { appName } from '../../../Global/Global';
import BannerImg from "../../../assets/images/appbanner.jpg";

const HomeBanner = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand)


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [showClose, setShowClose] = useState(false)

    const [showPopup, setShowPopup] = useState(false);
    const colorObj = {
        color: "#ffffff",
        position: "absolute",
        top: '35px',
        right: "-5px",
        fontSize: 11,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: "8px 7px",
        borderRadius: 2,
        cursor: "pointer"
    }
    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelClose2 = () => {
        setShowPopup(false);
    }

    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    useEffect(() => {
        const popupCookie = document.cookie
        if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
            setShowPopup(false)
        } else {
            setShowPopup(true)
        }
    }, []);

    return (
        <>
            <section className='hero-banner'>
                <div className='hero-banner-video'>
                    <iframe src="https://player.vimeo.com/video/892533752?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="app intro video"></iframe>
                </div>
                <div className='container'>
                    <div className='hero-banner-in'>
                        <div className='hero-banner-txt'>
                            <h6>Welcome to</h6>
                            <h1>{rebrand.data ? rebrand.data.name : <> <span>Toon</span>Reel</>}</h1>
                            <p>Bring your ideas to life with Pixar-quality animated Toon VSL, marketing, or content videos. With just a keyword and our AI magic, you can transform any concept into captivating animated stories featuring talking toons with flawless lip-sync, all in a matter of minutes. Customize your creations, and let your imagination soar with {rebrand.data ? rebrand.data.name : appName}</p>
                            {/* <p>Navigate a world of insights and creativity, as {rebrand.data ? rebrand.data.name : appName} empowers your content strategy. Elevate engagement, track growth, and conquer social media success.</p> */}
                            <a className='site-link mt-4' onClick={handleShow}> <span><BsPlayFill /> Watch Video</span> </a>
                        </div>
                        <div className="hero-banner-right">
                            {+auth.user.isClientAccount === 1 ? "" :
                                <Link onClick={handleShow2}>
                                    <div className="dashboard-block credits">
                                        <div className="credits-item">
                                            <div className="credits-item-left">
                                                <div className="credits-icon"><RiVideoAddFill /></div>
                                                <div className="credits-details">
                                                    <h2>Create New Project</h2>
                                                </div>
                                            </div>
                                            <div className="next-icon"><FiChevronRight /></div>
                                        </div>
                                    </div>
                                </Link>
                            }
                            <Link to={"/projects"}>
                                <div className="dashboard-block credits">
                                    <div className="credits-item">
                                        <div className="credits-item-left">
                                            <div className="credits-icon"><BsFillCollectionPlayFill /></div>
                                            <div className="credits-details">
                                                <h2>View Projects</h2>
                                            </div>
                                        </div>
                                        <div className="next-icon"><FiChevronRight /></div>
                                    </div>
                                </div>
                            </Link>
                            {+auth.user.isClientAccount === 1 ? "" :
                                <Link to={'/integration'}>
                                    <div className="dashboard-block credits">
                                        <div className="credits-item">
                                            <div className="credits-item-left">
                                                <div className="credits-icon"><MdCastConnected /></div>
                                                <div className="credits-details">
                                                    <h2>Intregration</h2>
                                                </div>
                                            </div>
                                            <div className="next-icon"><FiChevronRight /></div>
                                        </div>
                                    </div>
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Modal className="theme-modal ai_img_wrap video-modal ds" show={showPopup} onHide={handelClose2} centered>
                <Modal.Body>
                    <div onClick={() => setShowClose(true)} className="vidClose" >
                        <div style={{ position: "relative" }}>
                            <div className='modalClose' onClick={handleClose}><AiOutlineClose style={{
                                fontSize: "30px", marginBottom: "3px", top: '50px',
                                right: '-10px', position: "absolute"
                            }} /></div>
                            {showClose ?
                                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                        </div>

                    </div>
                    <div className="modalVid">
                        {/* <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
                                            <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
                                        </a> */}
                        <div className="para_ai h-100">
                            <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                                <p style={{
                                    fontSize: "55px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    lineHeight: "1.3",
                                    color: "#fff"
                                }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                                <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> Click Here</button>
                            </a>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <Modal className='theme-modal video-modal xl' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                    <div className="ratio ratio-16x9">
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/_oZ1kD1PJPo?si=wiYjqY74DbULa2yV?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1074" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="AiSocialsWalkthrough"></iframe>
                    </div>
                </Modal.Body>
            </Modal>

            <CreateModal
                show={show2}
                handleClose={handleClose2}
            />
        </>
    )
}

export default HomeBanner