import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandingReducer } from "./RebrandingReducer";
import { HelpReducer } from "./HelpReducer";
import { ImageReducer } from "./ImageReducer";
import { VideoReducer } from "./VideoReducer";
import { SocialReducer } from "./SocialReducer";
import { EditorReducer } from "./EditorReducer";


const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    rebrand: RebrandingReducer,
    help: HelpReducer,
    image: ImageReducer,
    social: SocialReducer,
    video: VideoReducer,
    editor : EditorReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;