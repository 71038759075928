import React, { useEffect, useState } from 'react';
import TitleBar from '../../../title-bar/TitleBar';
import Header from '../../../header/Header';
import Footer from '../../../footer/Footer';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from "query-string"
import { onFetchCampaign, onSearchContent, onUpdateCampaign } from '../../../../Redux/Actions/ProjectActions';
import CustomTopicModal from './CustomTopicModal';

const CreateVideo = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cid } = queryString.parse(location.search)

    const [pageLoader, setPageLoader] = useState(true)
    const [searchLoader, setSearchLoader] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false)

    const [selectedIdeaIndex, setSelectedIdeaIndex] = useState(false)
    const [createData, setCreateData] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleCustomTopic = () => setShow(true)

    const searchContent = (e, isSearch) => {
        e.preventDefault()
        setSearchLoader(true)
        if (isSearch) {
            setCreateData({
                ...createData,
                results: []
            })
        }

        dispatch(onSearchContent(createData, setCreateData, setSearchLoader, isSearch))
    }

    const handleUse = (curElem, index, type) => {
        setSubmitLoader(true)
        if (type === "custom") {
            let data = { ...createData }
            data.selected = curElem
            data.keyword = curElem
            dispatch(onUpdateCampaign(data, navigate, setSubmitLoader))
        } else {
            setSelectedIdeaIndex(index)
            let data = { ...createData }
            data.selected = curElem
            dispatch(onUpdateCampaign(data, navigate, setSubmitLoader))
        }
    }

    const fetchCampaign = () => {
        let data = {
            id: cid
        }
        dispatch(onFetchCampaign(data, setCreateData, setPageLoader))
    }

    useEffect(() => {
        if (cid) {
            fetchCampaign()
        }
    }, [cid])

    return (
        pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <TitleBar title="Create Video" />
                <Header />

                <section className='page-wrap'>

                    <div className='breadCr'>
                        <div className='container'>
                            <ul>
                                <li><Link to="/home">Dashboard</Link></li>
                                <li>/</li>
                                <li><span style={{ color: "#f7ab3a", fontWeight: 600 }}>Create Video</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='sm-container'>
                            <h2>Choose video topic using AI</h2>
                            <div className='cont-box'>
                                <form onSubmit={(e) => searchContent(e, true)}>
                                    <div className='common-input-wrap mt-0'>
                                        <textarea
                                            className='common-input'
                                            placeholder='Enter the Keyword or Description'
                                            value={createData.keyword}
                                            onChange={(e) => setCreateData({ ...createData, keyword: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className='common-input-wrap'>
                                        <button type='submit' className='site-link full' disabled={searchLoader}><span>{searchLoader && createData.results.length === 0 ? <>Searching... </> : "Search"}</span></button>
                                    </div>
                                </form>
                                <div className='text-center my-3'>
                                    <span style={{ cursor: "pointer", color: "#ee1085", fontWeight: "bold", textDecoration: "underline" }} onClick={handleCustomTopic}>I want to input my own video topic</span>
                                </div>

                                <div className='recommend-list'>
                                    <ul>
                                        <li className='justify-content-center' style={{ borderTop: "none" }}>
                                            {searchLoader && createData.results.length === 0 ?
                                                <i className="fa fa-spinner fa-spin ms-1" style={{ fontSize: 25, color: "#ee1085" }} /> :
                                                null}
                                        </li>
                                        {createData.results.length > 0 ?
                                            createData.results.map((curElem, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className='recommend-list-left'>{curElem}</div>
                                                        <button
                                                            type='button'
                                                            className={submitLoader ? "site-link-gray" : "site-link"}
                                                            onClick={() => handleUse(curElem, index)}
                                                            disabled={submitLoader}
                                                        // style={submitLoader ? { background: "gray", borderColor: "gray" } : {}}
                                                        >
                                                            <span>
                                                                {submitLoader && selectedIdeaIndex === index ? <>Using <i className="fa fa-spinner fa-spin ms-1" /></> : "Use This"}
                                                            </span>
                                                        </button>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }

                                    </ul>
                                </div>

                                {createData.results.length > 0 ?
                                    <div className='common-input-wrap text-center'>
                                        <button className='site-link' onClick={searchContent}><span> {(searchLoader && createData.results.length > 0) ? <>Loading <i className="fa fa-spinner fa-spin ms-1" /></> : "Load More"}</span></button>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <CustomTopicModal
                            show={show}
                            handleClose={handleClose}
                            handleUse={handleUse}
                            loader={submitLoader}
                        />

                    </div>
                </section>
                <Footer />
            </>
    )

}

export default CreateVideo;