import React, { useEffect, useState } from 'react'
import { BiTrash, BiChevronDownSquare } from "react-icons/bi"
import { MdOutlineEdit } from "react-icons/md"
import Dropdown from 'react-bootstrap/Dropdown';
import { BsBoxArrowInDown, BsChevronDown } from "react-icons/bs"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SweetAlert from './SweetAlert';
import { onCheckStatus, onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from 'react-redux';
import { CgScreen } from 'react-icons/cg'
import { FaSpinner } from 'react-icons/fa';
import { baseURL } from '../../Global/Global';
import PublishModal from '../pages/Projects/PublishModal';
import OcpCopyModal from '../pages/Projects/OcpCopyModal';
import dummy16x9 from "../../assets/images/16X9.png"
import dummy9x16 from "../../assets/images/9x16.png"
const VideoCard = ({ curElem, videos, setVideos, isDash }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [memberShip, setMemberShip] = useState([])



    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [ocp, setOcp] = useState({
        loader: false,
        data: "",
        show: false
    })

    const [common, setCommon] = useState({
        isEditabel: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
    })

    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = common.isDownloadable
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-campaign", data, videos, setVideos, setSweet))
    }

    const fetchVideo = (id, interval) => {
        let data = {
            cId: id
        }
        dispatch(onCheckStatus(data, common, setCommon, interval))
    }

    const handleOCP = (id) => {
        window.open(`${baseURL}ocp/${id}`, '_blank');
    }


    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = false
            let progress = 100
            if (curElem.video.length > 0) {
                videoUrl = curElem.video[0].videoUrl
                switch (+curElem.video[0].status) {
                    case 0:
                        text = ""
                        canEdit = true
                        url = "projects/create-video"
                        break;
                    case 1:
                        text = "Awaiting Script"
                        canEdit = true
                        url = +curElem.video[0].characterId === 0 ? "projects/choose-template" : "projects/create-script"
                        break;
                    case 2:
                        text = "Render Successful"
                        canEdit = true
                        url = "projects/editor"
                        break;
                    case 3:
                        canEdit = true
                        text = "Rendering Failed"
                        url = "projects/editor"
                        break;
                    case 4:
                        canEdit = true
                        text = "Script Generated"
                        url = +curElem.video[0].characterId === 0 ? "projects/choose-template" : "projects/create-script"
                        break;
                    case 5:
                        text = "Generating Video..."
                        showProg = true
                        activeIntrval = true
                        url = "projects/editor"
                        progress = 100
                        break;
                    case 6:
                        canEdit = true
                        text = "Awaiting Customization"
                        url = "projects/editor"
                        break;
                    case 7:
                        canEdit = true
                        text = "Downloading Resources..."
                        activeIntrval = true
                        showProg = true
                        url = "projects/editor"
                        break;
                    case 8:
                        canEdit = true
                        activeIntrval = true
                        url = "projects/editor"
                        showProg = true
                        text = curElem.video[0].renderProgress
                        break;
                    case 9:
                        text = "Rendering"
                        canEdit = true
                        showRend = true
                        activeIntrval = true
                        url = "projects/editor"
                        progress = curElem.video[0].renderProgress
                        break;
                    case 11:
                        canEdit = true
                        text = "Waiting in Queue..."
                        activeIntrval = true
                        showProg = true
                        url = "projects/editor"
                        break;
                    default:
                        text = ""
                        canEdit = true
                        url = "projects/create-video"
                }

                setCommon({
                    ...common,
                    statusText: text,
                    isEditabel: canEdit,
                    isDownloadable: videoUrl,
                    showRendering: showRend,
                    urlToRedirect: url,
                    showProgAnimation: showProg,
                    thumbUrl: curElem.video[0].thumbnailUrl,
                    failedData: curElem.video[0].logFile ? JSON.parse(curElem.video[0].logFile) : false,
                    progress: progress
                })

                if (activeIntrval) {
                    const intreval = setInterval(() => {
                        fetchVideo(curElem.id, intreval)
                    }, 5000)

                    return () => {
                        clearInterval(intreval)
                    }
                }
            }
        }
    }, [curElem])


    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <div className={`col-lg-3`}>
            <div className={curElem.dimension === "16x9" ? "video-project-16x9" : 'video-project-9x16'}>
                <div className={curElem.dimension === "16x9" ? 'video-project-img_16x9' : 'video-project-img_9x16'}>
                    <img alt="" src={common.thumbUrl ? common.thumbUrl : curElem.dimension === "16x9" ? dummy16x9 : dummy9x16} style={{ height: "100%", width: "100%" }} />
                </div>
                <div className="Video_Project_Render">
                    <div className="progress" style={{ height: 22, position: "relative", textAlign: "center" }}>
                        {common.showRendering ?
                            <div className="progress-bar progress-bar-striped progress-bar-animated text-dark" role="progressbar" style={{ width: `${common.progress}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                            </div>
                            :
                            common.showProgAnimation ?
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" title={common.statusText} style={{ width: `${common.progress}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                </div>
                                :
                                <div className='non-rendering-bar' data-tooltip-id={`my-tooltip-${curElem.id}`} style={common.statusText === "Rendering Failed" ? { background: "#DC143C" } : {}}>{common.statusText}</div>
                        }
                        {common.statusText === "Rendering Failed" ? <></> :
                            <div style={{ height: "100%", width: "100%", position: "absolute", top: 0 }}>
                                {common.showRendering ?
                                    <span className="progress-bar-text">{common.progress}% {common.statusText}</span>
                                    :
                                    common.showProgAnimation ?
                                        <span className="progress-bar-text " style={{ color: "#000", fontWeight: "700", fontSize: "13px" }}>{common.statusText}</span>
                                        : ""
                                }
                            </div>
                        }

                    </div>

                </div>
                <div className="video-project-bottom">
                    <div>
                        <h6 style={{ fontSize: '15px', textTransform: "capitalize" }} title={curElem.id} >{curElem.name}</h6>
                        <p>{curElem.created.split(" ")[0]}</p>
                    </div>
                    <div className="drop-style-1">
                        <Dropdown>
                            <Dropdown.Toggle variant="r" id="dropdown-basic">
                                <BsChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    {common.isDownloadable ?
                                        <>
                                            <li><Link onClick={handleDownload}><BsBoxArrowInDown /> Download</Link></li>
                                            <li><Link onClick={handleShow}><BiChevronDownSquare /> Publish</Link></li>
                                            {memberShip.includes("enterprise") ||
                                                memberShip.includes("fastpass") ||
                                                memberShip.includes("bundle") ?
                                                <li><Link onClick={() => handleOCP(curElem.id)}>{ocp.loader ? <FaSpinner className='spin' /> : <CgScreen />}  OCP</Link></li>
                                                : null}

                                        </>
                                        : null}
                                    {+auth.user.isClientAccount === 1 ? "" :
                                        <>
                                            {common.isEditabel ?
                                                <li ><Link to={`/${common.urlToRedirect}?cid=${curElem.id}`}><MdOutlineEdit /> Edit</Link></li> : null}
                                            <li onClick={() => onDelete(curElem.id)}><Link><BiTrash /> Delete</Link></li>
                                        </>
                                    }
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {common.failedData && common.statusText === "Rendering Failed" ?
                        <Tooltip
                            id={`my-tooltip-${curElem.id}`}
                            style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                            place="bottom"
                            content={common.failedData.errorMessage}
                        />
                        : ""}
                </div>
            </div>

            <PublishModal
                curElem={curElem}
                show={show}
                handleClose={handleClose}
            />

            <OcpCopyModal
                setOcp={setOcp}
                ocp={ocp}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this project?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </div>
    )
}

export default VideoCard