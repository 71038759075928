import React, { useEffect, useRef, useState } from 'react'
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { GiCheckMark } from 'react-icons/gi';
import { IoClose } from "react-icons/io5";


const TalkingHeadModal = (props) => {

    const [useLoader, setUseLoader] = useState(false)
    const [selected, setSelected] = useState({
        index: -1,
        url: "",
    })


    const handleUseData = () => {
        props.onSuccessUpload(selected.url, "image")
        props.handleClose()
    }

    const handleSelect = (curElem, index) => {
        setSelected({
            ...selected,
            index: index,
            url: curElem.url
        })
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} className='character_modal'>
            <Modal.Header >
                <Modal.Title ><h3>Characters</h3></Modal.Title>
                <button type="button" class="btn-close" aria-label="Close" onClick={props.handleClose} style={{ background: "none", }}><IoClose style={{ color: "#fff", fontSize: "34px" }} /></button>
            </Modal.Header>
            <Modal.Body>
                {/* {props.type === "char" ?
                    <Form onSubmit={handleSearch}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="10">
                                <Form.Control
                                    type="text"
                                    value={q}
                                    onChange={(e) => setQ(e.target.value)}
                                    required
                                    className='cont-inp'
                                />
                            </Form.Group>
                            <Col md="2">
                                <button type="submit" class="site-link w-100" > <span style={{ fontSize: "12px", width: "100%" }}>Search</span></button>
                            </Col>
                        </Row>
                    </Form> : null} */}
                <div className="All_character">
                    <Row className="mb-3">
                        {props.characterList.length > 0 ?
                            props.characterList.map((curElem, index) => {
                                return (
                                    <Col md="3" key={index} className='text-center'>
                                        <div
                                            className="character_img"
                                            style={{ cursor: "pointer", borderWidth: selected.index === index ? 2 : 1 }}
                                            onClick={() => handleSelect(curElem, index)}
                                        >
                                            <img src={curElem.url} alt="" />
                                            {
                                                selected.index === index ?
                                                    <div className='check_select'>
                                                        <GiCheckMark style={{ color: "#ee1085", fontSize: "35px" }} />
                                                    </div> : null
                                            }

                                        </div>
                                    </Col>
                                )
                            })
                            :
                            <Col md="12" className='text-center'>
                                {props.loader.fetch ?
                                    <FaSpinner className='spin' color="#ee1085" size={25} />
                                    : ""}
                            </Col>
                        }
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>

                <button
                    className='site-link'
                    onClick={handleUseData}
                    disabled={selected.index === -1}
                >
                    <span>{useLoader ? <>Using <FaSpinner className='spin' size={18} /> </> : "Use"}</span>
                </button>
            </Modal.Footer>

        </Modal>
    )
}

export default TalkingHeadModal
