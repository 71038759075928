import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { Link } from 'react-router-dom';
import { Search } from 'react-iconly'
import CreateModal from './CreateProject/CreateModal';
import { useSelector } from 'react-redux';
import VideoProjectContainer from './VideoProjectContainer';

const Projects = () => {

    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false);
    const [membership, setMembership] = useState([])

    const [q, setQ] = useState("")
    const [dimension, setDimension] = useState("")
    const [projType, setProjType] = useState(1)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (auth) {
            if (auth.user) {
                setMembership(auth.user.membership.split("__"))
            }
        }
    }, [auth])




    return (
        <>
            <TitleBar title="Projects" />
            <Header />

            <section className='page-wrap'>

                <div className='container'>
                    <h1 className='page-title'>My Projects</h1>
                    <div className='content-search'>
                        <div className='row'>
                            <div className='col-sm-3 col-xl-6'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input
                                        className='inp'
                                        type="text"
                                        placeholder='Search'
                                        name='search'
                                        onChange={(e) => setQ(e.target.value)}
                                        value={q}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-3 col-xl-2'>
                                <div className='input-wrap'>
                                    <select
                                        className='inp'
                                        name="proj"
                                        onChange={(e) => setProjType(e.target.value)}
                                        value={projType}
                                    >
                                        <option value={1}>Projects</option>
                                        {membership.includes("enterprise") || membership.includes("fastpass") || membership.includes("bundle") ?
                                            <option value={2}>Reelmerge Projects</option> : null}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xl-2'>
                                <div className='input-wrap'>
                                    <select
                                        className='inp'
                                        name="type"
                                        onChange={(e) => setDimension(e.target.value)}
                                        value={dimension}
                                    >
                                        <option value={""}>All</option>
                                        <option value={"16x9"}>16:9</option>
                                        <option value={"9x16"}>9:16</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xl-2'>
                                {+auth.user.isClientAccount === 1 ? "" :
                                    <Link className='site-link full bdr' onClick={handleShow}><span>+ Create Project</span></Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <VideoProjectContainer
                    dimension={dimension}
                    q={q}
                    type={projType}
                />

            </section>

            <CreateModal
                show={show}
                handleClose={handleClose}
            />
            <Footer />
        </>
    )

}

export default Projects;