import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../../Redux/Actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import Footer from '../../footer/Footer';
import TitleBar from '../../title-bar/TitleBar';
import { appName } from '../../../Global/Global';

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: ""
    })

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/dashboard')
        }
    }, [auth])


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(loginUser(loginInfo, setLoader))
    }

    return (
        <>
            <TitleBar title="Login" />
            <div className="login-wrap">
                <div className="login-head">
                    <div className="login-logo"><img alt='' src={require('../../../assets/images/logo.png')} /></div>
                </div>

                <div className="login-left">
                    <div className='welcome-txt'>
                        <h2>Welcome To ToonReel</h2>
                        <p>Transform ideas into Pixar-quality animated videos with AI magic! Easy, fast, and fun cartoon video creation with {appName}. Let’s get started!</p>
                    </div>
                </div>
                <div className="login-right">
                    <form className="login-right-main" onSubmit={handleSubmit}>
                        <h2>Login to Your Account Now</h2>
                        <div className='common-input-wrap'>
                            <label htmlFor="">Email Address</label>
                            <input
                                className='common-input'
                                type="email"
                                name="email"
                                placeholder='Email Address'
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className='common-input-wrap'>
                            <label htmlFor="">Password</label>
                            <input
                                className='common-input'
                                type="password"
                                name="password"
                                placeholder='Password'
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className='common-input-wrap'>
                            <button className='site-link full' type='submit'><span>Sign in to {appName} {loader ? <i className="fa fa-spinner fa-spin mx-1" /> : ""}</span></button>
                        </div>
                        <div className='common-input-wrap text-center'>
                            <p>Forgot your account details? <Link to="/forgot-password">Click here</Link></p>
                        </div>
                    </form>
                </div>
            </div>

            <div className='loginFooter'>
                <Footer></Footer>
            </div>
        </>
    )

}

export default Login;